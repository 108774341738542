import { render, staticRenderFns } from "./Logbook.vue?vue&type=template&id=6f3f3a76&scoped=true&"
import script from "./Logbook.vue?vue&type=script&lang=js&"
export * from "./Logbook.vue?vue&type=script&lang=js&"
import style0 from "./Logbook.vue?vue&type=style&index=0&id=6f3f3a76&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../.yarn/__virtual__/vue-loader-virtual-8057611e5a/0/cache/vue-loader-npm-15.9.8-438f4d0ff0-ca4c99b261.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f3f3a76",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../.yarn/__virtual__/vuetify-loader-virtual-ed9205d01e/0/cache/vuetify-loader-npm-1.7.3-f1d1b39131-81a3169810.zip/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';
installComponents(component, {VCard,VCardText,VCardTitle,VCol,VContainer,VIcon,VRow,VSpacer,VTimeline,VTimelineItem})


/* vuetify-loader */
import installDirectives from "!../../../../.yarn/__virtual__/vuetify-loader-virtual-ed9205d01e/0/cache/vuetify-loader-npm-1.7.3-f1d1b39131-81a3169810.zip/node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Scroll from 'vuetify/lib/directives/scroll'
installDirectives(component, {Scroll})
