<template>
  <v-container
    fluid
    class="fill-height fill-width"
  >
    <v-card class="fill-height fill-width d-flex flex-column">
      <v-card-title class="primary--text font-weight-bold">
        {{ $t('messages.views.admin.configuration.logbook.title') }}
      </v-card-title>

      <v-card-text class="flex-grow-1">
        <div class="d-flex flex-column flex-lg-row fill-height mx-n3">
          <div class="mx-lg-3 col-lg-4 mb-3">
            <v-row>
              <v-col>
                <o-date-time-field
                  v-model="search.from"
                  :rules="{
                    before: {
                      target: search.to,
                      strict: true,
                      withTime: true,
                    },
                  }"
                  label="messages.views.admin.configuration.logbook.search.from"
                  prepend-icon="mdi-calendar-start"
                  hide-details="auto"
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <o-date-time-field
                  v-model="search.to"
                  :rules="{
                    after: {
                      target: search.from,
                      strict: true,
                      withTime: true,
                    },
                  }"
                  label="messages.views.admin.configuration.logbook.search.to"
                  prepend-icon="mdi-calendar-end"
                  hide-details="auto"
                  dense
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <o-select
                  v-model="search.deviceType"
                  :items="deviceTypes"
                  label="messages.views.admin.configuration.logbook.search.deviceType"
                  prepend-icon="mdi-shape-outline"
                  hide-details="auto"
                  dense
                  clearable
                />
              </v-col>
            </v-row>
          </div>
          <div
            class="mx-3 flex-grow-1"
          >
            <div class="fill-height activities position-relative rounded">
              <perfect-scrollbar
                ref="virtualScrollTable"
                v-scroll.self="onScroll"
                class="absolute-scrollbar"
                :options="{suppressScrollX : true}"
              >
                <v-timeline
                  v-if="loading || (activities && activities.length)"
                  align-top
                  dense
                >
                  <v-timeline-item
                    v-for="edge in activities"
                    :key="edge.cursor"
                    :color="getActivityColor(edge.node.__typename, edge.node)"
                    :icon="getActivityIcon(edge.node.__typename, edge.node)"
                    small
                    fill-dot
                  >
                    <v-row
                      class="pt-1"
                    >
                      <v-col>
                        <span
                          class="cursor-pointer d-flex flex-md-row flex-column"
                          @click="() => showDetails(edge.node)"
                        >
                          <span class="text-caption mr-3 text-no-wrap">{{ edge.node.createdAt.toLocaleString() }}</span>
                          <strong class="text-no-wrap">{{ getActivityTitle(edge.node.__typename, edge.node) }}</strong>
                          <span
                            v-if="edge.node.shortDescription"
                            class="font-italic text-no-wrap ml-1 mr-3"
                          >
                            {{ '- ' + edge.node.shortDescription }}
                          </span>
                          <v-spacer />
                          <span
                            v-if="edge.node.userId && users[edge.node.userId]"
                            class="ml-md-3 mr-3 info--text"
                          >
                            <v-icon
                              color="info"
                              small
                            >
                              mdi-account
                            </v-icon>
                            {{ users[edge.node.userId].email }}
                          </span>
                        </span>
                      </v-col>
                    </v-row>
                  </v-timeline-item>

                  <v-timeline-item
                    v-if="loading"
                    color="grey"
                    disabled
                    small
                    fill-dot
                  >
                    <template v-slot:icon>
                      <o-loader color="white" />
                    </template>
                  </v-timeline-item>
                </v-timeline>

                <div
                  v-else
                  class="font-italic fill-height text-caption d-flex align-center justify-center"
                >
                  {{ $t('messages.views.admin.configuration.logbook.noDataAvailable') }}
                </div>
              </perfect-scrollbar>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <o-activity-details-dialog v-model="selectedActivity" />
  </v-container>
</template>

<script>
  import moment from 'moment';
  import {
    getActivityTitle,
    getEventPayloadLabel,
    getActivityColor,
    reformatActivityPayload,
    getActivityShortDescription,
    getActivityIcon
  } from '@/utils/activity-thread';

  const DEFAULT_ITEMS_PER_PAGE = 100;

  export default {
    data: function () {
      return {
        lastResults: null,
        activities: [],
        selectedActivity: null,
        loading: true,
        pagination: {
          itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
          first: DEFAULT_ITEMS_PER_PAGE,
          last: null,
          before: null,
          after: null,
        },
        search: {
          from: null,
          to: null,
          deviceType: null,
        },
        scrollInterval: null,
        deviceTypes: ['STATION_WATCHER', 'TRAFFIC_BOX', 'STATIONS'].map((value) => ({
          text: this.$t('constants.activity.event.payload.wamp.deviceType.' + value),
          value,
        })),
        users: {},
        userIdsToLoad: [],
      };
    },

    watch: {
      search: {
        handler () {
          this.pagination.before = null;
          this.pagination.after = null;
          this.lastResults = null;
          this.activities = [];
          this.$nextTick(this.loadActivities);
        },
        deep: true,
      },
    },

    mounted () {
      this.loadActivities();
    },

    methods: {
      getActivityTitle,
      getEventPayloadLabel,
      reformatActivityPayload,
      getActivityShortDescription,
      getActivityIcon,
      getActivityColor,
      showDetails: function (activity) {
        this.selectedActivity = activity;
      },
      onScroll: function () {
        if (this.scrollInterval === null) {
          this.scrollInterval = window.setTimeout(() => {
            this.scrollInterval = null;
            this.loadFromScrollPosition();
          }, 20);
        }
      },
      scrollToTop: function () {
        this.$refs.virtualScrollTable.$el.scrollTop = 0;
      },
      loadFromScrollPosition: function () {
        const el = this.$refs.virtualScrollTable.$el;

        if (!this.loading) {
          const { scrollTop, offsetHeight } = el;
          const scrollBottom = scrollTop + offsetHeight;

          if (scrollBottom / el.scrollHeight > 0.8) {
            this.nextPage();
          }
        }
      },
      nextPage: function () {
        if (this.lastResults.pageInfo.hasNextPage && this.lastResults.edges.length) {
          this.pagination.after = this.lastResults.edges[this.lastResults.edges.length - 1].cursor;
          this.loadActivities();
        }
      },
      loadActivities: function () {
        this.loading = true;
        this.$apollo.query({
          query: require('@gql/views/admin/configuration/logbook/searchActivities.gql'),
          client: 'activity-thread',
          variables: {
            first: this.pagination.first,
            last: this.pagination.last,
            before: this.pagination.before,
            after: this.pagination.after,
            orderBy: {
              createdAt: 'DESC',
            },
            from: this.search.from ? moment(this.search.from).utc().format('Y-MM-DD HH:mm:ss') : null,
            to: this.search.to ? moment(this.search.to).utc().format('Y-MM-DD HH:mm:ss') : null,
            deviceType: this.search.deviceType,
          },
          fetchPolicy: 'no-cache',
        })
          .then((result) => {
            for (const edge of result.data.searchActivities.edges) {
              edge.node.createdAt = moment.utc(edge.node.createdAt).toDate();
              edge.node.shortDescription = getActivityShortDescription(edge.node.__typename, edge.node);
              reformatActivityPayload(edge.node.__typename, edge.node);

              if (edge.node.userId && !this.userIdsToLoad.includes(edge.node.userId) && this.users[edge.node.userId] === undefined) {
                this.userIdsToLoad.push(edge.node.userId);
              }
            }

            this.lastResults = result.data.searchActivities;
            this.activities = this.activities.concat(result.data.searchActivities.edges);

            if (this.userIdsToLoad.length) {
              this.loadUsers().then(() => {
                this.loading = false;
              });
            } else {
              this.loading = false;
            }
          })
        ;
      },

      loadUsers: function () {
        return this.$apollo.query({
          query: require('@gql/views/admin/configuration/logbook/getUsersByIds.gql'),
          client: 'api-gateway',
          variables: {
            ids: this.userIdsToLoad,
          },
          fetchPolicy: 'no-cache',
        })
          .then((result) => {
            for (const i in result.data.getUsersByIds) {
              const user = result.data.getUsersByIds[i];
              this.users[user.id] = user;
              delete result.data.getUsersByIds[i];
            }
          })
        ;
      },
    },
  };
</script>

<style scoped lang="scss">
  .activities {
    border: solid 1px var(--v-primary-base);
  }
</style>
